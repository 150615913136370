.center-block{
    position: absolute;
    top: 50px;
    right: 50px;
    bottom: 50px;
    left: 50px;

    display: flex;
    flex-direction: column;
    align-content: center;

    text-align: center;
    
    background: linear-gradient(168deg, $purple 0%, $purple 50%, $blue 50%, $blue 100%);

    overflow: hidden;
    z-index: 9;

    @include media-breakpoint-down(md) {
        bottom: 100px;
    } 


    .purple{
        background: $purple;
        height: 100%;

        transition: $transition-base;
    }

    .white{
        margin: 0 auto;
        // animation: float 3s ease-in-out infinite;   
        z-index: 12;
        color: black;
        font-size: 24px;
        padding: 0 20px;

        div{
            background-color: #ffffff;
            border-radius: $border-radius;
            padding: 15px 20px;    
        }         
    }

    .blue{
        background: $blue;
        height: 100%;

        transition: $transition-base;
    }

    a{
        height: 100%;
        width: 100%;

        line-height: 1;
        font-size: 100px;
        color: white;
        text-decoration: none;

        @include media-breakpoint-down(md) {
            font-size: 80px;
        } 

        @include media-breakpoint-down(sm) {
            font-size: 60px;
        }


        .text{
            transition: $transition-base;
            padding: 0 20px;
        }
    
        &:hover{
            text-decoration: none;
            transform: skewY(-12deg) scale(1.1);
        }
    }
}