.switch {
  position: relative;
  display: inline-block;

  width: 100px;
  height: 34px;

  background: white;
  border-radius: 99rem;

  cursor: pointer;

  .ball {
    position: absolute;
    left: 4px;
    bottom: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 26px;
    width: 52px;

    padding: 5px;
    background-color: $primary;
    border-radius: 99rem;

    transition: $transition-base;
  
    img{
      height: 100%;
      opacity: 0;
      
      transition: $transition-base;
    }
  }

  input:checked + .ball {
    left: 42px;

    img{
      opacity: 1;
    }
  }

  // hide default input
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}