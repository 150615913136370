#glassesSelector {
  display: none;
}

.wrapper-whatsapp {

  input{
    width: 100%;
  }

  .card {
    margin-bottom: 1rem;
    background: white;
    color: black;

    transition: $transition-base;
    cursor: pointer;

    &:hover {
      background-color: #f1f1f1;
    }

    &.selected {
      background-color: $primary;
    }

    &.selected {
      .card-text {
        color: white;
      }
    }
  }

  .card-title {
    margin-bottom: 0;
  }

  .card-text {
    color: $gray-600;
  }

  .glass-option {
    display: flex;
    justify-content: center;
    
    padding: 10px 0;
    text-align: center;

    label {
      margin-bottom: 0;
    }

    p {
      margin-top: 3px;
      margin-bottom: 0;
    }
  }

  .formmessage {
    display: none;

    position: relative;

    border-radius: $border-radius;
    background: white;
    padding: 20px;

    color: black;
    font-style: italic;
    font-size: 16px;
    line-height: 2rem;

    .bubble {
      position: absolute;
      left: -19px;
      bottom: 20px;

      width: 20px;
    } 
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  .btn.disabled,
  .btn:disabled {
    display: none;
  }

  #formSubmit {
    margin-top: 1rem;
    font-size: 1.5rem;
    letter-spacing: .1em;
  }
}