.skew {
  transform: skewY(-12deg);
}

.skew--top {
  transform-origin: top left;
}

.skew--center {
  transform-origin: center center;
}

.skew--n {
  transform: skewY(12deg);
}