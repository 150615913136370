.page {
    position: relative;
    overflow: hidden;

    height: 100%;
    width: 100%;

    &--fullscreen{
        min-height: 100vh;
    }

    &--padding{
        padding: 50px;
    }


    > .background{
        position: fixed;
        top: -300%;
        right: -300%;
        bottom: -300%;
        left: -300%;

        background-position: center;
        background-image: url('../img/pattern2.svg');
        background-size: 7%;

        transform: rotate(-12deg);

        opacity: 0.75;

        @include media-breakpoint-down(md) {
            background-size: 10%;
        }

        @include media-breakpoint-down(xs) {
            background-size: 14%;
        }

        &.ouders{
            background-image: url('../img/pattern3.svg');
        }
    }
}

.app{
    position: relative;
    
    max-width: 1280px;
    margin: 0 auto;

    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
    
    overflow-x: hidden;
}