
// colors
$purple: #8d478e;
$blue: #0099df;
$orange: #ee7d00;

// bootstrap
$primary: #e20613;
$secondary: #1e97d4;



// fonts
$font-size-base:  1.13rem;
$font-family-base: myriad-pro, sans-serif;

$display1-size:               130px;
$display2-size:               190px;
$display3-size:               60px;
$display4-size:               40px;



// global
$border-radius: 1rem;



// buttons
$input-btn-padding-x: 1.5rem;



// form
$input-border-color: white;
$input-focus-border-color: $input-border-color;

$input-box-shadow: transparent;
$input-focus-box-shadow: transparent; 



// grid
$grid-breakpoints: (
  xxs: 0,
  xs: 420px,
  sm: 500px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  xs: 460px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);
