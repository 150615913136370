.c-p{
  cursor: pointer;
}

.cover{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.non-select{
  pointer-events: none;
}

.title{
  word-spacing: -.5rem;
  line-height: 1;

  word-break: break-word;

  &--smaller{
    font-size: 3rem;
  }
}

.twintig{
  color: $primary;
  font-size: 50px;
}

.tick-icon {
  @include transform(translatey(-5px));
}

.sources{
  position: relative;
  // margin-top: 50px;
  // bottom: 0;
  // width: 40%;
  background-color: white;
  z-index: 23890132892;
  font-size: 10px;
  color: #9f9f9f;
  padding: 10px 20px;
}