.icon-input{
    display: flex;
    width: 100%;
}

.icon-input--suffix{
    input{
        border-radius: 10px 0 0 10px;
    }

    .icon{
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
        width: 60px;
        
        background-color: $primary;

        border-radius: 0 10px 10px 0;
        cursor: pointer;

        img{
            max-width: none;
            height: 20px;
        }
    }
}
