.wrapper {
    position: relative;
    padding-top: 175px;
    padding-bottom: 175px;

    z-index: 1;

    > .background{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: -1;
    }
}

.wrapper-a {
    height: 500px;
    padding: 0;

    @include media-breakpoint-down(md) {
        height: 350px;
    }

    @include media-breakpoint-down(sm) {
        height: 250px;
    }
}

.wrapper-b {
    padding-top: 200px;
    padding-bottom: 50px;

    @media (max-width: 650px) {
        padding-top: 150px;
    }

    @media (max-width: 550px) {
        padding-top: 100px;
    }

    @include media-breakpoint-down(xs) {
        padding-top: 80px;
    }


    .hand{
        position: absolute;
        top: -400px;
        width: 80%;

        @include media-breakpoint-down(lg) {
            top: -350px;
        }

        @include media-breakpoint-down(md) {
            top: -250px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            top: -220px;
        }

        @include media-breakpoint-down(xs) {
            top: -180px;
        }

        @media (max-width: 380px) {
            top: -140px;
        }
    }

    .background{
        background: white;
    }
}

.wrapper-c {
    padding-bottom: 50px;

    color: white;

    .hand{
        position: absolute;
        top: -200px;
        right: 0;

        @include media-breakpoint-down(md) {
            top: -300px;
            right: -150px;
        } 

        @include media-breakpoint-down(sm) {
            top: -150px;
            width: 60%;
        }

        @media (max-width: 550px) {
            top: -120px;
            width: 70%;
        }

        @include media-breakpoint-down(xs) {
            top: -80px;
        }
    }

    .logo{
        display: block;
        margin-left: $font-size-base;
        width: 80%;
        margin-top: 50px;

        @include media-breakpoint-down(md) {
            width: 300px;
        } 

        @include media-breakpoint-down(sm) {
            width: 60%;
            margin-left: auto;
            margin-right: auto;
        } 
    }

    .background{
        background-image: url('../img/check1.svg');
        background-size: 200%;
        background-position: 10% 30%;

        @include media-breakpoint-down(md) {
            background-size: 350%;
        } 

        @include media-breakpoint-down(xs) {
            background-size: 600%;
        } 
    }


    &.ouder{
        > .background{

        background-image: url('../img/check3.svg');
        }
        padding-bottom: 250px;
    }
}

.wrapper-d{
    
    .hand-a{
        position: absolute;
        top: 300px;
        left: -40px;
        width: 50%;

        @include media-breakpoint-down(md) {
            top: 350px;
            left: -320px;
            width: 70%;
        }  

        @include media-breakpoint-down(sm) {
            top: 280px;
            width: 400px;
            left: -150px;
        }  

        @media (max-width: 650px) {
            top: 300px;
            left: -150px;
        }

        @media (max-width: 550px) {
            left: -100px;
            top: 350px;
            width: 300px;
        }

        @media (max-width: 518px) {
            top: 460px;
        }

        @media (max-width: 380px) {
            display: none;
        }


        &.ouder{
            top: 0px;

            @include media-breakpoint-down(lg) {
                left: -150px;
            }  

            @include media-breakpoint-down(md) {
                top: 260px;
                left: -120px;
                width: 400px;
            }  
            @include media-breakpoint-down(sm) {
                display: none;
            }  
        }
    }

    .hand-b{
        position: absolute;
        top: 230px;
        right: -220px; 

        @include media-breakpoint-down(lg) {
            top: 310px;
        }   

        @include media-breakpoint-down(md) {
            top: 400px;
        }  

        @include media-breakpoint-down(sm) {
            width: 600px;
        }  

        @media (max-width: 550px) {
            top: 450px;
        }

        @media (max-width: 518px) {
            top: 520px;
        }
        @media (max-width: 380px) {
            display: none;
        }
    }

    .hand-c{
        display: none;

        @media (max-width: 380px) {            
            display: block;

            margin-bottom: 20px;

            max-width: none;
            width: 150%;
        }

        &.ouder{
            display: none;
        }
    }

    .hand-d.ouder{
        display: none;


        @include media-breakpoint-down(sm) {
            display: block;
            margin-left: -150px;
            height: 300px;
            margin-bottom: 20px;
        }  

        @media (max-width: 650px) {
            margin-left: -100px;
        }

        @include media-breakpoint-down(xs) {
            margin-left: -50px;
        }
    }

    .toch{
        margin-top: -150px;
        margin-bottom: 350px;    

        @include media-breakpoint-down(md) {
            margin-top: -70px;
        }  

        @media (max-width: 550px) {
            margin-bottom: 300px;   
            margin-top: -20px; 
        }

        @media (max-width: 380px) {
            margin-bottom: 0;
        }

        &.ouder{
            margin-top: -80px;
            margin-bottom: 20px;
        }
    }

    .background{
        background-image: url('../img/check2.jpg');
    }

    .title.ouder{
        margin-bottom: 60px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        } 
    }

    &.ouder{
        z-index: 29;
    }
}

.wrapper-e{
    padding-top: 50px;
    padding-bottom: 250px;

    color: white;

    @include media-breakpoint-down(sm) {
        padding-bottom: 200px;
    } 


    .search-icon{
        max-width: none;
        height: 20px;
    }

    .switch-wrapper{
        margin: 0 10px;
    }

    .col-a{
        padding-top: 200px;

        @media (max-width: 440px) {
            padding-top: 100px;
        }
    }
    
    .hand{
        position: absolute;
        top: -90px;

        @include media-breakpoint-down(sm) {
            top: -150px;
        }
    }

    .background{
        background-image: url('../img/check3.svg');
        background-size: 220%;
        background-position: 90% 50%;

        @include media-breakpoint-down(md) {
            background-size: 400%;
        } 

        @include media-breakpoint-down(xs) {
            background-size: 600%;
        } 
    }
}



.wrapper-f{
    margin-top: -300px;
    height: 760px;

    @include media-breakpoint-down(md) {
        height: 660px;
    } 

    @include media-breakpoint-down(sm) {
        height: 600px; 
    } 

    @media (max-width: 600px) {
        height: 500px;
    }
    
    .hand{
        position: absolute;
        bottom: 0;
        right: 50px;
        width: 800px;  
        z-index: 4;

        @include media-breakpoint-down(md) {
            width: 600px; 
        } 

        @include media-breakpoint-down(sm) {
            width: 500px; 
        } 

        @media (max-width: 600px) {
            width: 400px;
        }

        @include media-breakpoint-down(xs) {
            right: 0;
            width: 100%;
        } 
    }

    
    &.ouder{

        @include media-breakpoint-down(xs) {
            height: 600px;
        } 
        
        @media (max-width: 400px) {
            height: 500px;
        }
    }
}