// var
@import 'var';

// fonts
@import 'fonts';

// vendors
@import "node_modules/bootstrap/scss/bootstrap";

// all
@import 'default';
@import 'mixins';
@import 'classes';
@import 'containers';
@import 'skews';
@import 'form-icon';
@import 'wrappers';
@import 'switch';
@import 'index';
@import 'whatsappform';
@import 'loader';

// other
@import 'dev/dev';
@import 'hotfix';