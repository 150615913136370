img{
    max-width: 100%;
}

ul{
    padding-left: $font-size-base;
}


a{
    color: inherit;
    text-decoration: underline;
}